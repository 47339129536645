import React from 'react'
import { Container, Button, Row, Col } from 'react-bootstrap';
import image5 from './pictures/personal-loan.webp';
import image6 from './pictures/istockphoto1.jpg';
import image7 from './pictures/WhatsApp.jpeg'

function Takeloan() {
  return (
    <div>
      <div className='secondsection' style={{ marginTop: '10rem', marginBottom: '100px' }}>
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
            <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
                <span style={{ color: 'red' }}>Quick Loans</span> in Just few Hours!
              </h1>
              <p style={{ textAlign: 'left', color: '#333333', lineHeight: 1.7 }}>
                Get a quick personal loan to meet your urgent financial needs, as a student or a salary earner.
              </p>
              <div className='d-flex flex-column flex-md-row' style={{ gap: '1rem' }}>
                <Button style={{ backgroundColor: 'red', color: '#ffffff', border: '#095390', width: '10rem' }}>
                  Get started
                </Button>
              </div>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image5}
                alt="App Mockup"
                style={{
                  width: '200%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='secondsection' style={{ marginTop: '30px', marginBottom: '50px' }}>
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
          <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image6}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
            <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
              Microbiz <span style={{ color: 'red' }}>Nano Loans</span> 
              </h1>
              <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
              Get up to N50,000 on the Microbiz Mobile App, or via USSD and take care of your personal needs.
              <ul>
                <li>4% Monthly Interest rate</li>
                <li>Tenor is between 1 - 2 Months</li>
                <li>Minimum Monthly Turn over in savings account</li>
                <li>2.5% Processing Fee including credit life insurance</li>
                <li>Loan Limit is between N10,00 and N50,000</li>
              </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='secondsection' style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
            <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
                Now-Now Cash <span style={{ color: 'red' }}>Payroll Loans</span>
              </h1>
              <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
                This is a loan product specially designed for Salary Earners in both public and private sectors to meet-up with their short-term financial needs.
              <ul>
                <li>4-5% Flat Monthly Interest Rate</li>
                <li>Tenor is between 3 - 12 Months</li>
                <li>Minimum loan amount is N50,000 & Maximum is 2 Million Naira per individual.</li>
                <li>Repayment is on monthly basis tied to salary account, using Remita and Direct Debit Mandate.</li>
              </ul>
              </p>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image7}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'right',
                  borderRadius: '10px'
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Takeloan
