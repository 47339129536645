import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import appmockup from './pictures/360shots_so.png';
import image1 from './pictures/newpic1.jpg';
import image2 from './pictures/newpic2.jpg';
import { ReactComponent as GooglePlayIcon } from './pictures/playstore 1.svg';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ReactComponent as Cbn } from './pictures/cbn.svg';
import { ReactComponent as Ndic } from './pictures/ndic.svg';
import { ReactComponent as Transfer } from './pictures/switch-horizontal.svg';
import { ReactComponent as Billpayment } from './pictures/library.svg';
import { ReactComponent as Chart } from './pictures/chart-bar.svg';
import { ReactComponent as Documentadd } from './pictures/document-add.svg';
import { ReactComponent as Cash } from './pictures/cash.svg';
import { ReactComponent as Briefcase } from './pictures/briefcase.svg';
import { ReactComponent as Trackexpense } from './pictures/presentation-chart-line.svg';
import { ReactComponent as Device } from './pictures/device-mobile.svg';
import MultiCarousel from './Multicarousel';
import Features from './Features';
import Heroimage from './Heroimage';

function Home() {
  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  return (
    <div className='homepage'>
      {/* Hero Section */}
      <motion.div
        className='herosection'
        style={{ backgroundColor: '#095390'}}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        <Container fluid className='d-flex flex-column' style={{ gap: '1%', paddingTop: '5rem' }}>
          <Row className='align-items-center' style={{ paddingBottom: '1rem', paddingTop: '3rem' }}>
            <Col md={6} className='text-white d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', lineHeight: 1.3, fontWeight: '600' }}>
                <span style={{ color: 'red' }}>Empowering</span> Your Financial Future
              </h1>
              <p style={{ textAlign: 'left', lineHeight: 1.7 }}>
                Discover affordable loans and secure savings options, supported by expert guidance to help you achieve your dreams.
              </p>
              <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem' }}>
                <Button variant='light' style={{ color: '#000000', borderColor: '#095390' }}>
                  <i className="fab fa-apple fa-xl" style={{ paddingRight: '0.5rem' }}></i>
                  <p className='d-inline' style={{ fontSize: '80%' }}>App Store</p>
                </Button>
                <Button variant='light' style={{ color: '#000000', borderColor: '#095390' }}>
                  <GooglePlayIcon width="24" height="24" /> <p className='d-inline' style={{ fontSize: '80%' }}>Play Store</p>
                </Button>
              </div>
              <div className='d-flex flex-wrap justify-content-center' style={{ gap: '1rem', marginTop: '1rem' }}>
                <Cbn width="120" height="70" />
                <Ndic width="120" height="70" />
              </div>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-content-center'>
              <motion.div         
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeInUp}>
                <Heroimage/>
              </motion.div>
              {/* <motion.img
                src={appmockup}
                alt="App Mockup"
                style={{
                  width: '80%',
                  maxWidth: '50rem',
                  height: 'auto',
                  objectFit: 'contain',
                  transform: 'scale(1.06)',
                  transformOrigin: 'center',
                }}
                variants={fadeInRight}
              /> */}
            </Col>
          </Row>
        </Container>
      </motion.div>

      {/* Second Section */}
      <motion.div
        className='secondsection'
        style={{ margin: '100px 0' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInLeft}
      >
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem' }}>
            <Col xs={12} md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
              <img
                src={image1}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
            <Col xs={12} md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 className='text-s-center text-left' style={{ color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
                Get Your <span style={{ color: 'red' }}>Bank Account</span> In Minutes!
              </h1>
              <p className='text-s-center text-left' style={{ color: 'black', lineHeight: 1.7 }}>
                Opening and operating a bank account has never been this easy. We have removed all the hassles. Just provide your relevant information, and you have a bank account of your own.
              </p>
              <div className='d-flex flex-column flex-md-row justify-content-center' style={{ gap: '1rem' }}>
                <Button style={{ backgroundColor: 'red', color: '#ffffff', border: 'none'}}>
                  Open Account
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.div>

      {/* Features Section */}
      <motion.div
        className="features"
        style={{ justifyContent: 'center', alignItems: 'center',marginBottom: '1rem' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
      <Features/>
      </motion.div>

      {/* Fourth Section */}
      <motion.div
        className='fourthsection'
        style={{ marginTop: '100px', marginBottom: '100px' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInRight}
      >
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%', marginBottom: '100px' }}>
            <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
              <img
                src={image2}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: '20rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
            <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 className='text-s-center text-left' style={{ color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
                <span style={{ color: 'red' }}>Loans</span> Without Stress
              </h1>
              <p className='text-s-center text-left' style={{ color: 'black', lineHeight: 1.7 }}>
                Accessing funds should never be an uphill task. Whether you need funds for emergencies or to fund a project, we’ve got you covered.
              </p>
              <div className='d-flex flex-column flex-md-row justify-content-center' style={{ gap: '1rem' }}>
                <Button style={{ backgroundColor: 'red', color: '#ffffff', border: 'none'}}>
                  Get Loans
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.div>

      {/* Carousel Section */}
      <MultiCarousel />
    </div>
  );
}

export default Home;

