import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import SMELoan from './components/SMELoan';
import RetailLoan from './components/RetailLoan';
import Company from './components/Company';
import Home from './components/Home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FAQ from './components/FAQ';
import Takeloan from './components/Takeloan';
import Savemoney from './components/Savemoney';
import Contact from './components/Contact';
import Terms from './components/Terms';
import Privacy from './components/Privacy';


function App() {
  return (
   <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/sme-loan" element={<SMELoan />} />
            <Route path="/retail-loan" element={<RetailLoan />} />
            <Route path="/takeloan" element={<Takeloan/>} />
            <Route path="/savemoney" element={<Savemoney/>} />
            <Route path="/company" element={<Company />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <FAQ/>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
