import React from 'react'
import { motion } from 'framer-motion';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { ReactComponent as Location } from './pictures/location-marker.svg';
import { ReactComponent as Mail } from './pictures/mail.svg';
import { ReactComponent as Phone} from './pictures/phone.svg';
import { ReactComponent as Chat } from './pictures/chat.svg';
import { ReactComponent as Facebook } from './pictures/facebook 1.svg';
import { ReactComponent as Linkedin } from './pictures/linkedin 1.svg';
import { ReactComponent as Twitter } from './pictures/twitter 1.svg';
import { ReactComponent as Instagram } from './pictures/instagram 1.svg';


function Contact() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  return (
    <motion.div     initial="hidden"
    whileInView="visible"
    viewport={{ once: true, amount: 0.2 }}
    variants={fadeInUp} style={{marginBottom: '100px'}}>
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', padding: '0 8%', marginBottom: '1rem', marginTop: '120px' }}>
      <Row className="mb-4">
        <p style={{
          color: '#095390',
          backgroundColor: '#F4F4F4',
          padding: '1rem',
          borderRadius: '4px',
          textAlign: 'center',
          margin: '0 auto',
          display: 'inline-block',
          width: 'auto'
        }}>Reach out to us</p>
      </Row>
      <Row className="text-center mb-4">
        <Col>
          <h5>We’re always here to help you</h5>
        </Col>
      </Row>
      <Row style={{ padding: '1.5%', backgroundColor: '#f4f4f4', borderRadius: '10px' }} className="justify-content-center">
      <Col xs={6} sm={6} md={4} lg={3} className="mb-4 d-flex" style={{paddingTop:'3%'}}>
        <div style={{ padding: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.5)', color: '#093590', borderRadius: '10px', textAlign: 'left', flex: '1', border: 'solid',borderColor: '#093590' }}>
          <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
            <Location width="24" height="24" />
          </div>
          <p style={{ marginBottom: '3%' }}>Our Location</p>
          <span style={{ fontSize: '0.8rem' }}>
            Block B1, Suites 13-16, New Mpape Modern Market, Maitama Ext., Abuja, Nigeria
          </span>
        </div>
      </Col>

      <Col xs={6} sm={6} md={4} lg={3} className="mb-4 d-flex" style={{paddingTop:'3%'}}>
        <div style={{ padding: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)', color: 'red', borderRadius: '10px', textAlign: 'left', flex: '1', border: 'solid',borderColor: 'red'  }}>
          <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
            <Mail width="24" height="24" />
          </div>
          <p style={{ marginBottom: '3%' }}>Email</p>
          <span style={{ fontSize: '0.8rem' }}>
            info@microbizmfb.com
          </span>
        </div>
      </Col>

      <Col xs={6} sm={6} md={4} lg={3} className="mb-4 d-flex" style={{paddingTop:'3%'}}>
        <div style={{ padding: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.15)', color: '#093590', borderRadius: '10px', textAlign: 'left', flex: '1', border: 'solid',borderColor: '#093590'  }}>
          <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
            <Phone width="24" height="24" />
          </div>
          <p style={{ marginBottom: '3%' }}>Mobile no.</p>
          <span style={{ fontSize: '0.8rem' }}>
            +234-814-636-4384
          </span>
        </div>
      </Col>

      <Col xs={6} sm={6} md={4} lg={3} className="mb-4 d-flex" style={{paddingTop:'3%'}}>
        <div style={{ padding: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)', color: 'red', borderRadius: '10px', textAlign: 'left', flex: '1', border: 'solid',borderColor: 'red'  }}>
          <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
            <Chat width="24" height="24" />
          </div>
          <p style={{ marginBottom: '3%' }}>Social media</p>
          <div className="d-flex align-items-center" style={{ gap: '5px' }}>
          <div style={{ borderRadius: '10px', backgroundColor: '#FFCCCC', width: '10rem', height: '2rem', display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
              <a href="https://www.example.com">
                <Facebook width="32" height="32" />
              </a>
              <a href="https://www.example.com">
                <Linkedin width="32" height="32" />
              </a>
              <a href="https://www.example.com">
                <Twitter width="32" height="32" />
              </a>
              <a href="https://www.example.com">
                <Instagram width="32" height="32" />
              </a>
          </div>
          </div>
        </div>
      </Col>
    </Row>

    </Container>
    </motion.div>
  )
}

export default Contact
