import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ReactComponent as Transfer } from './pictures/switch-horizontal.svg';
import { ReactComponent as Billpayment } from './pictures/library.svg';
import { ReactComponent as Chart } from './pictures/chart-bar.svg';
import { ReactComponent as Documentadd } from './pictures/document-add.svg';
import { ReactComponent as Cash } from './pictures/cash.svg';
import { ReactComponent as Briefcase } from './pictures/briefcase.svg';
import { ReactComponent as Trackexpense } from './pictures/presentation-chart-line.svg';
import { ReactComponent as Device } from './pictures/device-mobile.svg';
import '../App.css'

function Features() {

  return (
    <Container>
      <Row className="mb-4">
        <p style={{
          color: 'white',
          backgroundColor: 'red',
          padding: '1rem',
          borderRadius: '4px',
          textAlign: 'center',
          margin: '0 auto',
          display: 'inline-block',
          width: 'auto'
        }}>Features</p>
      </Row>
      <Row className="text-center mb-4">
        <Col>
          <h5>Explore Our Comprehensive Suite of Services</h5>
        </Col>
      </Row>
      {/* Feature Items */}
      <Row style={{ padding: '1.5%', backgroundColor: '#fafafa', borderRadius: '10px' }} className="justify-content-center">
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.2)',color: '#093590', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: '#093590',  transition: 'transform 0.3s ease', borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Transfer width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%',fontSize: '2rem' }}>Easy Transfer</p>
              <span style={{ fontSize: '0.9rem', }}>
              Transfer funds between your accounts or to others, ensuring swift processing and minimal complexity              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease', borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Billpayment width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%', fontSize: '2rem'}}>Bill Payment</p>
              <span style={{ fontSize: '0.9rem',}}>
              Settle bills for utilities, services, and subscriptions directly from your account, ensuring timely payments.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.2)',color: '#093590', borderRadius: '10px',textAlign: 'left',border: 'solid', borderColor: '#093590', transition: 'transform 0.3s ease', borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Chart width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%', fontSize: '2rem' }}>Balance Checks</p>
              <span style={{ fontSize: '0.9rem' }}>
                 View the current status of your account balances, helping you stay informed about your financial health.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease', borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Documentadd width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%', fontSize: '2rem' }}>Account Opening</p>
              <span style={{ fontSize: '0.9rem' }}>
                Easily open new accounts, with concise steps and options tailored to your financial needs and preferences.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.2)',color: '#093590', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: '#093590', transition: 'transform 0.3s ease',borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Cash width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%',fontSize: '2rem' }}>Take Loans</p>
              <span style={{ fontSize: '0.9rem' }}>
                Access quick and flexible loan options tailored to your needs, with transparent terms and rapid approval.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease',borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Briefcase width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%',fontSize: '2rem' }}>Save Money</p>
              <span style={{ fontSize: '0.9rem' }}>
                 Grow your savings with our secure and accessible savings solutions, designed to help you achieve your financial goals.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.2)',color: '#093590', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: '#093590', transition: 'transform 0.3s ease',borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Trackexpense width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%',fontSize: '2rem' }}>Track Expenses</p>
              <span style={{ fontSize: '0.9rem' }}>
              Easily monitor and manage your spending habits with our expense tracking tools, ensuring financial control.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease',borderWidth:"1px"}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Device width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%',fontSize: '2rem' }}>Airtime and Data</p>
              <span style={{ fontSize: '0.9rem' }}>
              Top up seamlessly with our convenient airtime and data purchase options, ensuring you're always connected.
              </span>
            </div>
          </Col>
      </Row>
    </Container>
  );
}

export default Features;

