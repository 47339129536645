import React from 'react';
import { Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';


import image1 from './retail/grouo loan.png'
import image2 from './retail/minimon loan2.png'
import image3 from './retail/sabi.avif'
import image4 from './retail/wedow.jpg'
import image5 from './pictures/New_Headers.webp';

function RetailLoan() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };
  
  return (
    <div>
      <motion.div className='herosection'         initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp} style={{ backgroundColor: '#fff', padding: '0', marginTop: '100px' }}>
        <Container fluid className='d-flex flex-column'>
          {/* Intro Section */}
          <Row className='align-items-center' style={{ paddingBottom: '1rem', paddingTop: '5rem' }}>
            <Col md={6} className='d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', lineHeight: 1.3, color: '#095390', fontWeight: '600' }}>
                Empower your business with our <span style={{ color: 'red' }}>retail loans</span>
              </h1>
              <p style={{ textAlign: 'left', lineHeight: 1.7, color: 'black' }}>
                Tailored financial solutions to help your business grow, manage cash flow, and seize new opportunities. Enjoy competitive rates, flexible terms, and fast approval.
              </p>
            </Col>

            {/* Image Carousel */}
            <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image5}
                alt="App Mockup"
                style={{
                  width: '200%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
          </Row>
        </Container>
      </motion.div>

      <Container style={{ paddingTop: '10rem' }}>
      <Row className="mb-4" style={{ justifyContent: 'center' }}>
            <p style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',
              width: 'auto'
            }}>
              Retail Loan Plans
            </p>
          </Row>
          <Row className="text-center mb-5">
            <Col>
              <h5>Explore our diverse loan options to find the best fit for your needs</h5>
            </Col>
          </Row>

      

          <motion.div                
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeInUp}  id='team' style={{marginBottom: '100px'}}>
        <Container>
        <div style={{ padding: '2% 8%' }}>
          {/* First Row */}
        
          <Row className="justify-content-center" style={{gap:"10px",
          }}> {/* Changed g-3 to g-2 */}

  <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3"> {/* Added mb-3 for margin bottom */}
    <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'box-shadow 0.3s ease',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px'
    }}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)';
      }}>
      <section id="card1" className="Pard">
        <img
          className="d-block w-100"
          src={image1}
          alt="Umar Aliyu Hajji"
          style={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
            borderRadius: '10px',
            height: '250px',
            transition: 'transform 0.3s ease-in-out',
          }}
          onMouseOver={(e) => {
            e.target.style.transform = 'scale(1.05)';
          }}
          onMouseOut={(e) => {
            e.target.style.transform = 'scale(1)';
          }}
        />
      </section>
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h5 style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'black',
          transition: 'color 0.3s ease',
        }}>
          Micro Group Loan
        </h5>
        <p style={{
          fontSize: '1rem',
          fontStyle: 'italic',
          transition: 'color 0.3s ease',
        }}>
          Provides small groups with the financial support needed to collectively grow their businesses. Ideal for community-based business ventures aiming for collective prosperity.
        </p>
      </div>
    </div>
  </Col>

  <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3"> {/* Added mb-3 */}
    <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'box-shadow 0.3s ease',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px',
    }}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)';
      }}>
      <section id="card2" className="Pard">
        <img
          className="d-block w-100"
          src={image2}
          alt="Umar Aliyu Hajji"
          style={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
            borderRadius: '10px',
            height: '250px',
            transition: 'transform 0.3s ease-in-out',
          }}
          onMouseOver={(e) => {
            e.target.style.transform = 'scale(1.05)';
          }}
          onMouseOut={(e) => {
            e.target.style.transform = 'scale(1)';
          }}
        />
      </section>
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h5 style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'black',
          transition: 'color 0.3s ease',
        }}>
          Minimonie Loan
        </h5>
        <p style={{
          fontSize: '1rem',
          fontStyle: 'italic',
          transition: 'color 0.3s ease',
        }}>
          Offers short-term financing solutions to small business owners, helping them manage cash flow and operational needs effectively. Perfect for addressing immediate business expenses.
        </p>
      </div>
    </div>
  </Col>

  <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3"> {/* Added mb-3 */}
    <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'box-shadow 0.3s ease',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px',
    }}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)';
      }}>
      <section id="card3" className="Pard">
        <img
          className="d-block w-100"
          src={image3}
          alt="Umar Aliyu Hajji"
          style={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
            borderRadius: '10px',
            height: '250px',
            transition: 'transform 0.3s ease-in-out',
          }}
          onMouseOver={(e) => {
            e.target.style.transform = 'scale(1.05)';
          }}
          onMouseOut={(e) => {
            e.target.style.transform = 'scale(1)';
          }}
        />
      </section>
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h5 style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'black',
          transition: 'color 0.3s ease',
        }}>
          Sabitrader Loan
        </h5>
        <p style={{
          fontSize: '1rem',
          fontStyle: 'italic',
          transition: 'color 0.3s ease',
        }}>
          Tailored specifically for traders, this loan facilitates business expansion and inventory increase, enabling traders to scale their operations smoothly.
        </p>
      </div>
    </div>
  </Col>

  <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3"> {/* Added mb-3 */}
    <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'box-shadow 0.3s ease',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px',
    }}
      onMouseOver={(e) => {
        e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)';
      }}>
      <section id="card4" className="Pard">
        <img
          className="d-block w-100"
          src={image4}
          alt="Umar Aliyu Hajji"
          style={{
            width: '100%',
            objectFit: 'cover',
            objectPosition: 'top',
            borderRadius: '10px',
            height: '250px',
            transition: 'transform 0.3s ease-in-out',
          }}
          onMouseOver={(e) => {
            e.target.style.transform = 'scale(1.05)';
          }}
          onMouseOut={(e) => {
            e.target.style.transform = 'scale(1)';
          }}
        />
      </section>
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h5 style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: 'black',
          transition: 'color 0.3s ease',
        }}>
          Karfe Steel Loan
        </h5>
        <p style={{
          fontSize: '1rem',
          fontStyle: 'italic',
          transition: 'color 0.3s ease',
        }}>
          Designed for artisans in the steel and manufacturing industry, helping them invest in materials, equipment, and workforce, fostering business growth.
        </p>
      </div>
    </div>
  </Col>

</Row>

        </div>
        </Container>
      </motion.div>

       
      </Container>
    </div>
  );
}

export default RetailLoan;
