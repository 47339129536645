import React from 'react';
import { Container, Row, Col, Carousel, Card} from 'react-bootstrap';
import { motion } from 'framer-motion';
import logo from './pictures/UBA.jpg';
import pic1 from './pictures/gr2.jpg'; // Correct path
import pic2 from './pictures/gr3.jpg'; // Correct path



import image1 from './sme picture/asset .jpg'
import image2 from './sme picture/overdraft.jpg'
import image3 from './sme picture/biz blus.jpg'
import image4 from './sme picture/biz puz.webp'
import image5 from './pictures/women-africa.jpg'


import logo1 from './pictures/remita-logo.jpg';
import logo2 from './pictures/zenith-bank-logo.jpg';
import logo3 from './pictures/fidelity-bank-logo.jpg';
import logo4 from './pictures/nibss-logo.jpg';
import logo5 from './pictures/access-bank-logo.jpg';
import logo6 from './pictures/partners-worldwide-logo.jpg';
import logo7 from './pictures/crc-cb-logo.jpg';
import logo8 from './pictures/first-central-logo (1).jpg';
import logo9 from './pictures/uba-logo.jpg';
import logo10 from './pictures/peak-mfi-logo.jpg';

function SMELoan() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };
  
  return (
    <div>
    <motion.div initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp} className='secondsection' style={{ marginTop: '10rem', marginBottom: '50px' }}>
      <Container fluid className='d-flex flex-column'>
        {/* Intro Section */}
        <Row className='align-items-center' style={{paddingBottom: '1rem'}}>
          <Col md={6} className='text-white d-flex flex-column align-items-start' style={{ padding: '1rem' }}>
            <h1 style={{ textAlign: 'left', lineHeight: 1.3, color: '#095390', fontWeight: '600' }}>
              Unlock Your Business Potential with Our <span style={{ color: 'red' }}>SME Loans</span> 
            </h1>
            <p style={{ textAlign: 'left', lineHeight: 1.7, color: 'black' }}>
            Fuel your business growth with our specialized SME loan products, designed to provide the financial support your business needs to thrive              </p>
            <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem' }}>
              {/* Add your content here */}
            </div>
            <div className='d-flex flex-wrap justify-content-center' style={{ gap: '1rem', marginTop: '1rem' }}>
              {/* Add your content here */}
            </div>
          </Col>

          {/* Image Carousel */}
          <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image5}
                alt="App Mockup"
                style={{
                  width: '200%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
        </Row>
      </Container>
    </motion.div>
    <div>
      <Container>
      <Row className="mb-4" style={{ justifyContent: 'center' }}>
            <p style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',
              width: 'auto'
            }}>
              SME Loan Plans
            </p>
          </Row>
          <Row className="text-center mb-5">
            <Col>
              <h5>Discover the options that best suit your goals</h5>
            </Col>
          </Row>
          <motion.div                  initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeInUp} id='team'>
        <Container>
        <div style={{ padding: '2% 8%' }}>
          {/* First Row */}
        
         
          <Row className="justify-content-center" style={{gap:"10px",
          }}>
            
         <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
  gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image1}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Asset Acquisition Loan
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Finance the purchase of essential business assets with flexible repayment terms to enhance your operational capacity.                       </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
    gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Overdraft
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Maintain your business’s cash flow and cover unexpected expenses with our convenient overdraft facility.     </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image3}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      BizPlus Loan
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Access substantial funding to expand and scale your business operations, tailored to meet larger financial requirements.     </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center mb-3">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px'
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image4}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'top', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Female Merchant Loan
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Empower female entrepreneurs with financial solutions designed to support business growth and sustainability.        </p>
                  </div>
                </div>
            </Col>




             </Row>
        </div>
        </Container>
      </motion.div>


      </Container>


    </div>
  </div>
  );
}

export default SMELoan;
