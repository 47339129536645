import React from 'react'
import { Container} from 'react-bootstrap';
function Terms() {
  return (
    <Container><div style={{ marginTop: '8rem' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Terms and Condition</h1>
      </div>

      <div style={{ display: 'flex', marginTop: '5rem', justifyContent: 'space-evenly'}}>
        
        <div style={{width: '20%'}}>
          <p>
            <a href='#' style={{color: 'red'}}>Term of Use</a>
          </p>
          <p>
            <a href='#' style={{color: 'red'}}>Term of Service</a>
          </p>
        </div>

        <div style={{width: '70%'}}>
          <h2>Terms of Use</h2>
          
          <div>
            <p>By using this website (www.microbizmfb.com), any of our websites and/or services, you agree to these
             Terms of Use. The website Privacy Policy, Acceptable Use Policy, and Terms of Service (where applicable) are incorporated by reference into these Terms of Use.</p>
          </div>

          <div>
          <h2>About Us</h2>
            <p>We are a Digital Microfinance Bank incorporated with the Corporate Affairs Commission in Nigeria, and Licensed by the Central bank of Nigeria (CBN), with the aim of providing digital financial inclusion services to the people at the base of the economic pyramid, particularly in the rural and semi-urban areas.</p>
            <p>This Terms of Use is an agreement between you and Microbiz. It details Microbiz’s obligations to you. It also highlights certain risks on using the services and you must consider such risks carefully as you will be bound by the provision of this Agreement through your use of this website or any of our services.</p>
            <p>We are a limited liability company incorporated with the Corporate Affairs Commission in Nigeria with the aim of providing digital financial inclusion services to the people at the base of the economic pyramid, particularly in the rural and semi-urban areas.</p>
          </div>

          <div>
          <h2>Privacy Policy</h2>
            <p>Microbiz is committed to managing your Personal Information in line with global industry best practices. You can read our Privacy Policy to understand how we use your information and the steps we take to protect your information.</p>
          </div>

          <div>
          <h2>Age Restriction</h2>
            <p>Our website and services are not directed to children under 18. We do not knowingly transact or provide any services to children under 18.</p>
          </div>

          <div>
          <h2>Disputes & Reversal</h2>
            <p>If you believe that an unauthorized or otherwise problematic transaction has taken place, you agree to notify us immediately, to enable us take action to help prevent financial loss.</p>
            <p>All claims against us related to payments should be made within 45 (forty-five) days after the date of such payment. It will be taken that you waive all claims against us, to the fullest extent of the law after the said period of time.</p>
            <p>Your transaction ID and/or transaction details will be required to resolve all disputes</p>
          </div>

          <div>
          <h2>Disclaimers</h2>
            <p>WE TRY TO KEEP OUR PLATFORM AVAILABLE AT ALL TIMES, BUG-FREE AND SAFE, HOWEVER, YOU USE IT AT YOUR OWN RISK.</p>
            <p>OUR WEBSITE AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY EXPRESS, IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS). WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, MICROBIZ MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH OUR WEBSITE OR FROM MICROBIZ, ITS PARENTS, SUBSIDIARIES, OR OTHER AFFILIATED COMPANIES, OR ITS OR THEIR SUPPLIERS (OR THE RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS OF ANY SUCH ENTITIES) (COLLECTIVELY, "MICROBIZ PARTIES") SHALL CREATE ANY WARRANTY.</p>
          </div>

          <div>
          <h2>Limitation of Liability</h2>
            <p>IN NO EVENT WILL ANY OF THE MICROBIZ PARTIES BE LIABLE FOR (A) ANY INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR (B) ANY DAMAGES WHATSOEVER IN EXCESS OF THE AMOUNT OF THE TRANSACTION OR TWENTY THOUSAND UNITED STATES DOLLARS (US$20,000.00) DOLLARS, WHICHEVER IS LESSER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF REVENUES, LOST PROFITS, LOSS OF GOODWILL, LOSS OF USE, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE LOSSES), ARISING OUT OF OR IN CONNECTION WITH MICROBIZ'S WEBSITE OR SERVICES (INCLUDING, WITHOUT LIMITATION, USE, INABILITY TO USE, OR THE RESULTS OF USE OF MICROBIZ'S WEBSITES OR SERVICES), WHETHER SUCH DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT, STATUTE, OR ANY OTHER LEGAL THEORY.</p>
          </div>

          <div>
          <h2>Exclusions</h2>
            <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain damages. Accordingly, some of the above disclaimers and limitations of liability may not apply to you. To the extent that any Microbiz Party may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of the Microbiz’s Party's liability shall be the minimum permitted under such applicable law.</p>
          </div>

          <div>
          <h2>Updates, Modifications & Amendments</h2>
            <p>We may need to update, modify or amend our Terms of Use as our technology evolves. We reserve the right to make changes to this Terms of Use at any time by giving notice to users on this page.</p>
            <p>We advise that you check this page often, referring to the date of the last modification on the page If a user objects to any of the changes to the Terms of Use, the User must cease using our website and/or services immediately.</p>
          </div>

          <div>
          <h2>Applicable Law</h2>
            <p>These Terms of Use shall be interpreted and governed by the laws currently in force in the Federal Republic of Nigeria.</p>
          </div>

          <div>
          <h2>Legal Disputes</h2>
            <p>We shall make an effort to settle all disputes amicably. Any dispute arising out of this Terms of Use, which cannot be settled, by mutual agreement/negotiation within 1 (one) month shall be referred to arbitration by a single arbitrator at the Lagos Multi-Door Courthouse (“LMDC”) and governed by the Arbitration and Conciliation Act, Cap A10, Laws of the Federal Republic of Nigeria. The arbitrator shall be appointed by both of us (we and you), where both of us are unable to agree on the choice of an arbitrator, the choice of arbitration shall be referred to the LMDC. The findings of the arbitrator and subsequent award shall be binding on both of us. Each of us shall bear our respective costs in connection with the Arbitration. Venue for the arbitration shall be Lagos, Nigeria.</p>
          </div>

          <div>
          <h2>Severability</h2>
            <p>If any portion of these Terms of Use is held by any court or tribunal to be invalid or unenforceable, either in whole or in part, then that part shall be severed from these Terms of Use and shall not affect the validity or enforceability of any other part in this Terms of Use.</p>
          </div>

          <div>
          <h2>Terms of Service</h2>
            <p>By signing up for an account on this website (www.microbizmfb.com), any of our websites and/or services, you are deemed a merchant and agree to these Merchant Terms of Service (the “Agreement”).</p>
            <p>PLEASE READ THESE CUSTOMER TERMS OF SERVICE CAREFULLY BEFORE SIGNING UP AS A CUSTOMER. If you do not agree to any or all of these Terms of Service, DO NOT USE THIS SITE!</p>
          </div>

          <div>
          <h2>Agreement</h2>
            <p>These Customer Terms of Service is an agreement between you and Microbiz. It details Microbiz’s obligations to you. It also highlights certain risks on using the services and you must consider such risks carefully as you will be bound by the provision of this Agreement through your use of this website or any of our services.</p>
          </div>

          <div>
          <h2>Registration</h2>
            <p>To use Microbiz Platform, you have to create a Microbiz account by registering. To register, you will provide us with certain information such as your email, first name, last name, email and phone number and we may seek to verify your information, (by ourselves or through third parties), after which we will approve your account unless deemed risky. You give us permission to do all these.</p>
          </div>

          <div>
          <h2>Change of Information</h2>
            <p>In the event that you change any information provided to us at registration including your email, address, phone number, etc, you agree to notify us within 14 days of such change. We may be unable to respond to you if you contact us from an address, telephone number or email account that is not registered with us.</p>
          </div>

          <div>
          <h2>Representation and Warranties</h2>
          <p>You represent and warrant to Microbiz that:</p>
            <p style={{margin: '0'}}>1. You have full power and authority to enter into, execute, deliver and perform this Agreement;</p>
            <p>2. You are duly organised, authorised and in good standing under the laws of the Federal Republic of Nigeria or any state, region or country of your organisation and are duly authorised to do business in all other states, regions or countries in which your business operates.</p>
          </div>

          <div>
          <h2>Account Security</h2>
            <p>You agree not to allow anyone else to have or use your password details and to comply with all reasonable instructions we may issue regarding account access and security. In the event you share your password details, Microbiz will not be liable to you for losses or damages. You will also take all reasonable steps to protect the security of the personal electronic device through which you access Microbiz’s services (including, without limitation, using PIN and/or password protected personally configured device functionality to access Microbiz’s services and not sharing your device with other people).</p>
          </div>

          <div>
          <h2>Trademark License</h2>
            <p>We hereby grant you a revocable, non-exclusive, non-transferable license to use Microbiz’s trademarks used to identify our services (the “Trademarks”) solely in conjunction with the use of our services. You agree that you will not at any time during or after this Agreement assert or claim any interest in or do anything that may adversely affect the validity of any Trademark or any other trademark, trade name or product designation belonging to or licensed to Microbiz (including, without limitation registering or attempting to register any Trademark or any such other trademark, trade name or product designation). Upon expiration or termination of this Agreement, you will immediately cease all display, advertising and use of all of the Trademarks.</p>
          </div>

          <div>
          <h2>Intellectual Property</h2>
            <p>We do not grant any right or license to any Microbiz intellectual property rights by implication, estoppel or otherwise other than those expressly mentioned in this Agreement.</p>
            <p>Each party shall retain all intellectual property rights including all ownership rights, title, and interest in and to its own products and services, subject only to the rights and licenses specifically granted herein.</p>
          </div>

          <div>
          <h2>Publicity</h2>
            <p>You hereby grant Microbiz permissions to use your name and logo in our marketing materials including, but not limited to use on our website, in customer listings, in interviews and in press releases. Such Publicity does not imply an endorsement for your products and services.</p>
          </div>

          <div>
          <h2>Confidential Information</h2>
            <p>The parties acknowledge that in the performance of their duties under this Agreement, either party may communicate to the other (or its designees) certain confidential and proprietary information, including without limitation information concerning each party’s services, know how, technology, techniques, or business or marketing plans (collectively, the “Confidential Information”) all of which are confidential and proprietary to, and trade secrets of, the disclosing party. Confidential Information does not include information that: (i) is public knowledge at the time of disclosure by the disclosing party; (ii) becomes public knowledge or known to the receiving party after disclosure by the disclosing party other than by breach of the receiving party’s obligations under this section or by breach of a third party’s confidentiality obligations; (iii) was known by the receiving party prior to disclosure by the disclosing party other than by breach of a third party’s confidentiality obligations; or (iv) is independently developed by the receiving party.</p>
            <p>As a condition to the receipt of the Confidential Information from the disclosing party, the receiving party shall: (i) not disclose in any manner, directly or indirectly, to any third party any portion of the disclosing party’s Confidential Information; (ii) not use the disclosing party’s Confidential Information in any fashion except to perform its duties under this Agreement or with the disclosing party’s express prior written consent; (iii) disclose the disclosing party’s Confidential Information, in whole or in part, only to employees and agents who need to have access thereto for the receiving party’s internal business purposes; (iv) take all necessary steps to ensure that its employees and agents are informed of and comply with the confidentiality restrictions contained in this Agreement; and (v) take all necessary precautions to protect the confidentiality of the Confidential Information received hereunder and exercise at least the same degree of care in safeguarding the Confidential Information as it would with its own confidential information, and in no event shall apply less than a reasonable standard of care to prevent disclosure.</p>
          </div>

          <div>
          <h2>Termination</h2>
            <p>You may terminate this Agreement by closing your Microbiz Account.</p>
            <p>We may suspend your Microbiz Account and your access to Microbiz services and any funds, or terminate this Agreement, if;</p>
            <p style={{margin: '0'}}>1. You do not comply with any of the provisions of this Agreement;</p>
            <p style={{margin: '0'}}>2. We are required to do so by a Law;</p>
            <p style={{margin: '0'}}>3. We are directed by a Card Network or issuing financial institution; or</p>
            <p style={{margin: '0'}}>4. Where a suspicious or fraudulent transaction occurs.</p>

          </div>

          <div>
          <h2>Restricted Activities & Acceptable Use Policy</h2>
            <p>You are independently responsible for complying with all applicable laws related to your use of our website and services. However, by accessing or using Microbiz Platform, you agree to comply with the terms and conditions of our Acceptable Use Policy and are restricted from the activities specified in it which you can read on our Acceptable Use Policy page.</p>
          </div>

          <div>
          <h2>Exclusions</h2>
            <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain damages. Accordingly, some of the above disclaimers and limitations of liability may not apply to you. To the extent that any Microbiz Party may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of the Microbiz’s Party's liability shall be the minimum permitted under such applicable law.</p>
          </div>

          <div>
          <h2>Indemnity</h2>
            <p>You agree to defend, indemnify, and hold Microbiz, its officers, directors, employees, agents, licensors, and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of these Agreement.</p>
          </div>

          <div>
          <h2>Miscellaneous</h2>
            <p>You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Assigning or sub-contracting any of your rights or obligations under these Terms of Use to any third party is prohibited. We reserve the right to transfer, assign or sub-contract the benefit of the whole or part of any rights or obligations under these Terms of Use to any third party.</p>
          </div>

          <div>
          <h2>Age Restriction</h2>
            <p>Our website and services are not directed to children under 18. We do not knowingly transact or provide any services to children under 18.</p>
          </div>

          <h4 style={{color: 'red'}}>Effective Date: June 01, 2022</h4>

        </div>

      </div>
    </div>
    
</Container>
    
    
  )
}

export default Terms