import React from 'react'
import { motion } from 'framer-motion';
import { Container, Button, Row, Col } from 'react-bootstrap';
import image8 from './pictures/average_savings.jpg'
import image1 from './save money picture/regular saving.jpg'
import image2 from './save money picture/kiddes_saving.jpg'
import image3 from './save money picture/master account.jpg'
import image4 from './save money picture/education saving.avif'
import image5 from './save money picture/Gathering-of-members.jpg'
import image6 from './save money picture/fixed depo.jpg'


function Savemoney() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  return (
    <div>
      <motion.div         initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp} className='secondsection' style={{ marginTop: '10rem', marginBottom: '50px' }}>
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
            <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 style={{ textAlign: 'left', color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
               Earn more, when you <span style={{ color: 'red' }}>save with Microbiz</span>
              </h1>
              <p style={{ textAlign: 'left', color: 'black', lineHeight: 1.7 }}>
               Build wealth with our high-yield savings and fixed deposit accounts. Get up to 13% returns on your investments with Microbiz. Saving money should come with rewards.
              </p>
            </Col>
            <Col md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
            <img
                src={image8}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: '22rem',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
          </Row>
        </Container>
      </motion.div>
      <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: '8%', paddingRight: '8%', marginBottom: '50px' }}>
      <Row className="mb-4">
          <p  style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',  // This ensures the width wraps the content
              width: 'auto'             // Makes sure the width adjusts based on the content
            }}>Save with us</p>
        </Row>
        <Row className="text-center mb-4">
          <Col>
            <h5>Enjoy secure and rewarding savings options </h5>
          </Col>
        </Row>
        <motion.div                 initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={fadeInUp} id='team'>
        <Container>
        <div style={{ padding: '2% 8%' }}>
          {/* First Row */}
        
        
         <Row  className="g-1 justify-content-center">
            
         <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '400px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image1}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Regular Savings
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                This is a savings product meant for everyday people. This account is best tailored to serve micro, small, and medium sized businesses, as well as individuals.                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      gap: '15px'
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Kiddies Savings
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
It's never too early to start a savings account for your child, towards a better future. Save for your children & manage their portfolio even from a tender age.                 </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image3}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Master Account
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Every big dream is just a target away. Open a Microbiz Target Easy Savings (TEASA) account and save money towards a goal. Earn attractive benefits on your savings.                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image4}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Education Savings
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
Save towards your ward's educational needs, from infant to adulthood. With this account, it's easier to pay for their schools fees, as it helps you save early enough.                      </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image5}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Group Savings
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
               Microbiz Group Savings (MIGSA) Account is a regular savings account tailored to serve micro-business women who are under a group-lending methodology.
                        </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
      border: '1px solid #e6e6e6',
      borderRadius: '10px',
      overflow: 'hidden',
      padding: '1rem',
      width: 'auto',
      maxWidth: '300px',
      height: '30rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'center', // Center the content horizontally
      transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image6}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                    </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Fixed Term Deposits
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                With this account, you can invest with Microbiz, and earn as high as 12% ROI per annum. Relax while your money works for you. No hassles!  </p>
                  </div>
                </div>
            </Col>
         </Row>
       
        </div>
        </Container>
      </motion.div>
      </Container>
    </div>
  )
}

export default Savemoney
