import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button, Modal } from 'react-bootstrap';
import logo from './pictures/Microbiz short logo.png';
import logoWhite from './pictures/Microbiz white logo.png'; // Add your white logo here

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [navbarColor, setNavbarColor] = useState('#095390'); // Initial blue background
  const [textColor, setTextColor] = useState('white'); // Initial white text
  const [logoSrc, setLogoSrc] = useState(logo); // Initial blue logo
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleInternetBankingClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Scroll to the top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Handle scroll change
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setNavbarColor('rgba(255,255,255, 0.95)'); // Change background to white
        setTextColor('black');   // Change text to black
        setLogoSrc(logoWhite);   // Change to white version of the logo
      } else {
        setNavbarColor('#095390'); // Back to blue background
        setTextColor('white');   // Change text to white
        setLogoSrc(logo);        // Change back to the original blue logo
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isPersonalActive = location.pathname === "/takeloan" || location.pathname === "/savemoney";
  const isBusinessActive = location.pathname === "/sme-loan" || location.pathname === "/retail-loan";
  const isHomeActive = location.pathname === "/home";
  const isCompanyActive = location.pathname === "/company";
  const isContactActive = location.pathname === "/contact";

  return (
    <>
      <Navbar 
        expand="lg" 
        style={{ 
          padding: '1.64% 1rem',
          paddingTop: '1%',
          paddingBottom: '0.5%',
          backgroundColor: navbarColor, // Dynamic background color
          position: 'fixed', 
          width: '100%', 
          zIndex: '10000', 
          transition: 'background-color 0.3s ease' // Smooth transition
        }}
      >
        <div className='d-flex justify-content-between align-items-center w-100'>
          <a className="navbar-brand" href="#">
            <img src={logoSrc} alt="Logo" width="145" height="45" className="d-inline-block align-top" />
          </a>
          <div style={{ textAlign: 'center' }}>
            <Navbar.Toggle aria-controls="navbarNavDropdown" onClick={toggleMenu} style={{ border: 'none' }} />
          </div>
        </div>
        <Navbar.Collapse 
          id="navbarNavDropdown" 
          className={`${isMenuOpen ? 'show' : 'collapse'}`} 
          style={{ transition: 'max-height 0.3s ease-in-out', maxHeight: isMenuOpen ? '300px' : '0' }}
        >
          <Nav 
            className="ml-auto d-flex flex-column flex-lg-row justify-content-between align-items-start" 
            style={{ flexGrow: '1', gap: '0.8rem' }}
          >
            <Nav.Link 
              as={Link} 
              to="/home" 
              style={{ 
                color: isHomeActive ? 'red' : textColor, // Active state color
                textDecoration: 'none', // No underline
                opacity: isHomeActive ? '1' : '0.5' 
              }}
            >
              <p className='d-inline'>Home</p>
            </Nav.Link>

            <NavDropdown 
              title={
                <span style={{ color: isPersonalActive ? 'red' : textColor, opacity: isPersonalActive ? '1' : '0.5', textDecoration: 'none' }}>
                  <p className='d-inline'>Personal</p>
                </span>
              } 
              id="personal-dropdown" 
              className="custom-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/takeloan">Take Loan</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/savemoney">Save Money</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown 
              title={
                <span style={{ color: isBusinessActive ? 'red' : textColor, opacity: isBusinessActive ? '1' : '0.5', textDecoration: 'none' }}>
                  <p className='d-inline'>Business</p>
                </span>
              } 
              id="business-dropdown" 
              className="custom-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/sme-loan">SME Loan</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/retail-loan">Retail Loan</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link 
              as={Link} 
              to="/company" 
              style={{ 
                color: isCompanyActive ? 'red' : textColor, 
                textDecoration: 'none', // No underline
                opacity: isCompanyActive ? '1' : '0.5' 
              }}
            >
              <p className='d-inline'>Company</p>
            </Nav.Link>

            <Nav.Link 
              as={Link} 
              to="/contact" 
              style={{ 
                color: isContactActive ? 'red' : textColor, 
                textDecoration: 'none', // No underline
                opacity: isContactActive ? '1' : '0.5' 
              }}
            >
              <p className='d-inline'>Contact</p>
            </Nav.Link>

            <Button 
              style={{ 
                backgroundColor: 'red', 
                color: '#ffffff', 
                border: '#ffffff', 
                minWidth: '150px', 
                width: 'auto'
              }}
              onClick={handleInternetBankingClick}
            >
              <p className='d-inline'>Internet Banking</p>
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>Coming Soon</Modal.Title>
        </Modal.Header>
        <Modal.Body>Internet Banking is coming soon! Stay tuned for updates.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;



