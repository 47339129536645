import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import logo from './pictures/logo.png';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ReactComponent as Location } from './pictures/location-marker.svg';
import { ReactComponent as Facebook } from './pictures/facebook 1.svg';
import { ReactComponent as Linkedin } from './pictures/linkedin 1.svg';
import { ReactComponent as Twitter } from './pictures/twitter 1.svg';
import { ReactComponent as Instagram } from './pictures/instagram 1.svg';
import image20 from './pictures/MICROBIZ MICROFINANCE BANK LIMITED 2023.jpg';

function Footer() {
  return (
    <footer className="text-dark text-center py-5" style={{ backgroundColor: '#DAE5EF' }}>
      <Container className='my-5'>
        {/* Second Row: Text Columns with Ordering */}
        <Row>
          <Col className="text-start" xs={{ order: 1 }}>
            <Row>
              <Col className="d-flex justify-content-start" style={{marginBottom: '1rem'}}>
                <img
                  src={logo}
                  alt="Microbiz logo"
                  style={{ maxWidth: '150px', height: 'auto' }}
                />
              </Col>
            </Row>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start'}}>
              <i className="bi bi-geo-alt me-2" style={{ fontSize: '16px' }}></i>
              <span>
                Block B1, Suites 13-16, New Mpape Modern Market, Maitama Ext., Abuja, Nigeria
              </span>
            </p>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start' }}>
              <i className="bi bi-envelope me-2" style={{ fontSize: '16px' }}></i>
              <span>contact@microbizmfb.com</span>
            </p>
            <p className="d-flex align-items-center" style={{ fontSize: '14px', justifyContent: 'flex-start' }}>
              <i className="bi bi-telephone me-2" style={{ fontSize: '16px' }}></i>
              <span>+234-814-636-4384</span>
            </p>
          </Col>

          <Col xs={{ order: 2 }}>
            <p className="fw-bold" style={{ fontSize: '14px' }}>Company</p>
            <p className="mb-3" ><a href='#about' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>About</a></p>
            <p className="mb-3" ><a href='#team' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>Team</a></p>
            <p style={{ fontSize: '14px' }}>Careers</p>
          </Col>
          <Col xs={{ order: 3 }}>
            <p className="fw-bold" style={{ fontSize: '14px' }}>Personal</p>
            <p className="mb-3" ><a href='/savemoney' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>Save money</a></p>
            <p className="mb-3" ><a href='/takeloan' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>Take loan</a></p>
          </Col>

          <Col xs={{ order: 3 }}>
            <p className="fw-bold" style={{ fontSize: '14px' }}>Contact</p>
            <p className="mb-3" ><a href='/terms' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>Terms and conditions</a></p>
            <p className="mb-3" ><a href='/privacy' style={{ fontSize: '14px', textDecoration: 'none', color: 'black' }}>Privacy</a></p>
          </Col>

          <Col xs={{ order: 4 }} className="text-center">
            <p className="fw-bold mb-3" style={{ fontSize: '14px' }}>Our Socials</p>
              <div className="d-flex justify-content-center align-items-center" style={{ gap: '5px', marginBottom: '1rem' }}>
                <a href="https://www.example.com">
                  <Facebook width="28" height="28" />
                </a>
                <a href="https://www.example.com">
                  <Linkedin width="28" height="28" />
                </a>
                <a href="https://www.example.com">
                  <Twitter width="28" height="28" />
                </a>
                <a href="https://www.example.com">
                  <Instagram width="28" height="28" />
                </a>
              </div>
              <img
              src={image20}
              alt="App Mockup"
              style={{
                width: '100%',
                maxWidth: '5rem',
                height: 'auto',
                objectFit: 'cover',
                objectPosition: 'top',
                alignSelf: 'start'
              }}
            />
          </Col>
        </Row>

        

        <hr />
        <p style={{ fontSize: '12px', margin: '1rem 0', textAlign: 'left' }}>
          © 2022 Microbiz MFBank Limited (RC1876581). All rights reserved. Microbiz MFBank Limited is registered with the Corporate Affairs Commission (CAC) with the registration number RC1876581 and fully licensed by The Central Bank of Nigeria (CBN), and insured by The Nigeria Deposit Insurance Corporation (NDIC).
          “Microbiz MFB” and “Microbiz MFBank" are trademarks of Microbiz Microfinance Bank Limited, Maitama Ext, Abuja: Suites 13-16, New Mpape Modern Market, Abuja, Nigeria.
          All text, graphics, audio files, code, downloadable material, and other works on this website are the copyrighted works of Microbiz MFBank Limited. All Rights Reserved. Any unauthorized redistribution or reproduction of any copyrighted materials on this website is strictly prohibited. Other products and company names are trademarks of their respective owners. This website contains simulated images; actual appearance may vary.
        </p>
      </Container>
    </footer>
  );
}

export default Footer;
