import React from 'react';
import { Container, Row, Col, Card, Form, Button, Carousel} from 'react-bootstrap';
import logo from './pictures/family.jpg';
import { motion } from 'framer-motion';
import { ReactComponent as Transfer } from './pictures/PhHandshakeLight 1.svg';
import './css file/companey.css'
import './css file/styles.css'
import '../App.css'

import pic1 from './pictures/pic.jpg';
import pic2 from './pictures/gr3.jpg';

import { ReactComponent as Billpayment } from './pictures/PhRepeat 1.svg';
import { ReactComponent as Chart} from './pictures/chart-bar.svg';
import { ReactComponent as Documentadd } from './pictures/document-add.svg';
import { ReactComponent as Cash } from './pictures/cash.svg';
import { ReactComponent as Briefcase } from './pictures/cube.svg';
import { ReactComponent as Trackexpense } from './pictures/user.svg';
import { ReactComponent as Device } from './pictures/star.svg';


import image1 from './pictures/us.jpeg';
import image2 from './pictures/md.jpg';
import image3 from './pictures/wa.jpeg';
import image4 from './pictures/oc.jpeg';
import image5 from './pictures/tr.jpeg';
import image6 from './pictures/ed 2.jpg';

import image7 from './pictures/is22.jpg';
import image8 from './pictures/felix.jpg';
import image9 from './pictures/amara.jpg';
import image10 from './pictures/hit.jpg';
import image11 from './pictures/kc.jpeg';
import image12 from './pictures/is.jpg';
import image13 from './pictures/hr.jpg';


// Updated card data with image paths, titles, and descriptions
const cardData = [
  { image: image1, title: 'Danjuma Usman', text: 'Chairman, Board of Directors' },
  { image: image2, title: 'Joe Onyeabor', text: 'Managing Director/CEO' },
  { image: image3, title: 'Wakama Sotonye', text: 'Director' },
  { image: image4, title: 'Ochoche Owoicho', text: 'Director' },
  { image: image5, title: 'Teryila Ubwa', text: 'Independent Director' },
  { image: image6, title: 'Abiodun Yusuph', text: 'Board Secretary/CFO' }
];

const cardData1 = [
  { image: image7, title: 'Isibor Okhihie', text: 'Head, Internal Audit & Control' },
  { image: image8, title: 'Felix Ekemini Pius', text: 'Head, Risk Management' },
  { image: image9, title: 'Amarachi Ihekwoaba', text: 'Head, Banking Operations' },
  { image: image10, title: 'Umar Aliyu Hajji', text: 'Head, Information Technology' },
  { image: image11, title: 'Kelechi Kalu', text: 'Head, Support Services' },
  { image: image12, title: 'Isaac P Oloche', text: 'Head Innovation' },
  { image: image13, title: 'Adaorah Orkafansi', text: 'Head, Human Resources' },
];

function Company () {


   // Animation variants
   const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };


  return (
    <div>
           <motion.div
        className='secondsection'
        style={{  margin: '10rem 0' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInRight}
      >
        <Container fluid style={{ gap: '40px' }}>
          <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem' }}>
            <Col xs={12} md={6} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
              <img
                src={logo}
                alt="App Mockup"
                style={{
                  width: '100%',
                  maxWidth: '40rem',
                  height: 'auto',
                  objectFit: 'cover',
                  objectPosition: 'top',
                  borderRadius: '10px'
                }}
              />
            </Col>
            <Col xs={12} md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
              <h1 className='text-s-center text-left' style={{ color: '#095390', lineHeight: 1.3, fontWeight: '600' }}>
                A Bank with<span style={{ color: 'red' }}> a touch of Class!</span>
              </h1>
              <p className='text-s-center text-left' style={{ color: 'black', lineHeight: 1.7 }}>
                At Microbiz, we believe in excellence in service delivery while providing access to financial services for the people at the base of the economic pyramid.
              </p>
              <div className='d-flex flex-column flex-md-row justify-content-center' style={{ gap: '1rem' }}>
                <Button style={{ backgroundColor: 'red', color: '#ffffff', border: 'none'}}>
                  Bank with us
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
    </motion.div>


    
      {/* Cards Section */}
      <div className="text-dark text-center py-5 my-5" id='about' style={{ backgroundColor: '#F8F8F8' }}>
      <motion.div
        className='herosection'
        style={{padding: '0 8%' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
      >
        
        <Container>
        
          <Row className="d-flex justify-content-center cards">
            <Col md={5} className="mb-4 d-flex justify-content-center card red">
              <div
              style={{ width: '22rem', height: 'auto', 
              }} className="mx-2">
                <Card.Body className="text-start">
                <h5 className="mb-2 text-muted tip">
                  <i className="bi bi-bookmark fs-3"  style={{color: 'red', border: '#095390', width: '10rem' }}> Vision</i> {/* 'text-danger' makes the icon red */}
                 
                </h5>
                  <p className='second-text'  style={{color: 'red', border: '#095390', }}>
                    To be the foremost Digital Microfinance Bank in Nigeria.
                  </p>
                </Card.Body>
              </div>
            </Col>

            <Col md={5} className="mb-4 d-flex justify-content-center  card blue">
              <div style={{ width: '22rem', height: 'auto', 
              }} className="mx-2">
                <Card.Body className="text-start">
                <h5 className="mb-2 text-muted tip">
                <i className="bi bi-bookmark fs-3"  style={{color: '#093590', border: '#095390',  }}> Mission</i> {/* Inline CSS for blue */}
                </h5>

                <p className='second-text'  style={{color: '#093590', border: '#095390',  }}>
                    An innovative Digital Microfinance Bank driven by passion and commitment to provide life-changing solutions towards sustainable empowerment for everyday people.
                  </p>
                </Card.Body>
              </div>
            </Col>
          </Row>
        </Container>

        </motion.div>
      </div>

      {/* Core Values Section */}
      
    <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', paddingLeft: '6rem', paddingRight: '6rem', marginBottom: '1rem' }}>
    <motion.div
        className='secondsection'
        style={{ padding: '0 8%', margin: '100px 0' }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
        >
    <Row className="mb-4">
        <p  style={{
            color: '#095390',
            backgroundColor: '#F4F4F4',
            padding: '1rem',
            borderRadius: '4px',
            textAlign: 'center',
            margin: '0 auto',
            display: 'inline-block',  // This ensures the width wraps the content
            width: 'auto'             // Makes sure the width adjusts based on the content
          }}>Our core values</p>
      </Row>
      
      <Row className="text-center mb-4">
        <Col>
          <h5>Guiding principles that define our purpose</h5>
        </Col>
      </Row>
      <Row style={{ padding: '1.5%', backgroundColor: '#fafafa', borderRadius: '10px' }} className="justify-content-center">
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.5)',color: '#093590', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: '#093590',  transition: 'transform 0.3s ease'}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Transfer width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%' }}>Integrity</p>
              <span style={{ fontSize: '0.8rem' }}>
                Our work ethics & business principles are aligned with our service promise to all stakeholders.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease'}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Device width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%' }}>Excellence</p>
              <span style={{ fontSize: '0.8rem' }}>
                We delight our customers with our products and services, using care, diligence, professionalism, and best practice.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.5)',color: '#093590', borderRadius: '10px',textAlign: 'left',border: 'solid', borderColor: '#093590', transition: 'transform 0.3s ease'}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Billpayment width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%' }}>Sustainability</p>
              <span style={{ fontSize: '0.8rem' }}>
                Our processes, business model, and products are designed for the present and future generations
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(255, 0, 0, 0.1)',color: 'red', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: 'red', transition: 'transform 0.3s ease'}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#FFCCCC', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Trackexpense width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%' }}>Discipline</p>
              <span style={{ fontSize: '0.8rem' }}>
              We develop the culture and mindset of execution, making us focus on our goals and service, inline with our business ethics.
              </span>
            </div>
          </Col>
          <Col xs={6} sm={6} md={4} lg={4} className="mb-4 feature-column" style={{paddingTop:'3%'}}>
            <div style={{ padding: '1rem', paddingTop: '1rem', backgroundColor: 'rgba(9, 83, 144, 0.5)',color: '#093590', borderRadius: '10px',textAlign: 'left', border: 'solid', borderColor: '#093590', transition: 'transform 0.3s ease'}}>
              <div style={{ borderRadius: '50%', backgroundColor: '#516C91', width: '2.5rem', height: '2.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0.3rem', marginBottom: '10px' }}>
                <Briefcase width="24" height="24" />
              </div>
              <p style={{ marginBottom: '3%' }}>Innovation</p>
              <span style={{ fontSize: '0.8rem' }}>
                Our standards include better ways of doing things, to amaze our customers with new products, & excellent customer service.
              </span>
            </div>
          </Col>


      </Row>
      </motion.div>
    </Container>

      {/* Team Section */}
      <section  id='team'>
        <Container>
          
        <div style={{ padding: '2% 8%' }}>
          {/* First Row */}
          
    <motion.div
        className='secondsection'
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={fadeInUp}
        >
      <Row className="mb-4" style={{ justifyContent: 'center' }}>
            <p style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',
              width: 'auto'
            }}>
              Our Team
            </p>
          </Row>
          <Row className="text-center mb-5">
            <Col>
              <h5>Board of Directors</h5>
            </Col>
          </Row>

          </motion.div>


            <Row className="g-3">
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image1}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                    <div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Danjuma Usman
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Chairman, Board of Directors
                    </p>
                  </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

               
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Joe Onyeabor
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Managing Director/CEO
                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image3}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Wakama Sotonye
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Director
                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image4}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Ochoche Owoicho
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Director
                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image5}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Teryila Ubwa
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Independent Director
                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image6}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Abiodun Yusuph
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Board Secretary/CFO
                    </p>
                  </div>
                </div>
            </Col>
            </Row>

          
        </div>
        </Container>
      </section>

      <Container>
        <div style={{ padding: '2% 8%' }}>
          {/* First Row */}
        
          <Row className="text-center mb-5">
            <Col>
              <h5>Management Team</h5>
            </Col>
          </Row>


          <Row className="g-3">

          <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image2}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

               
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <a href='https://web.facebook.com/joseph.onyeabor'>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>
                          </a>
                            <a href='https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAAYTlioB_sqw6AyOS1afiVt3SokHjrb3cDw&keywords=joe%20onyeabor&origin=RICH_QUERY_SUGGESTION&position=0&searchId=70128851-0794-4172-9895-a892ddbc68f4&sid=m!3&spellCorrectionEnabled=false'>
                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>
                          </a>
                            <a href='https://x.com/OnyeaborJoe'>
                          <p className="Pard__description">
                          Twitter-x
                            <i class="bi bi-twitter-x"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          </a>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Joe Onyeabor
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Managing Director/CEO
                    </p>
                  </div>
                </div>
            </Col>

          <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image6}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>
                            <a href='https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAAgV_mEBQQZ9Y5_3Qt5gsxHqYn58KAiV2Xo&keywords=abiodun%20yusuph&origin=RICH_QUERY_SUGGESTION&position=0&searchId=595f59fe-3d62-4c7f-b1d5-249a1ebace63&sid=mIj&spellCorrectionEnabled=false'>
                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>
                          </a>


                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Abiodun Yusuph
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Board Secretary/CFO
                    </p>
                  </div>
                </div>
            </Col>

            
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image10}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

                
                          <div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>
                          <a href='https://www.linkedin.com/in/umar-hajji-aliyu/'>
                          <p className="Pard__description">
                           
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>
                          </a>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Umar Aliyu Hajji
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Head, Information Technology
                    </p>
                  </div>
                </div>
            </Col>

          <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image7}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Isibor Okhihie
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                   Head, Internal Audit & Control
                    </p>
                  </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image8}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Felix Ekemini Pius
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Head, Risk Management
                    </p>
                    
                      </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image9}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

               
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Amarachi Ihekwoaba
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Head, Banking Operations
                    </p>
                  </div>
                </div>
            </Col>


            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image11}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Kelechi Kalu
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Head, Support Services
                    </p>
                  </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image12}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

               
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Isaac P Oloche
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Head Innovation
                    </p>
                  </div>
                </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4} className="d-flex justify-content-center align-items-center">
            <div style={{
                  border: '1px solid #e6e6e6',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '1rem',
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'box-shadow 0.3s ease', // Add a smooth shadow transition
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.2)'; // Increase shadow on hover
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = '2px 5px 10px rgba(0, 0, 0, 0.1)'; // Reset shadow
                  }}>
                <section id="card1" class="Pard">
                <img
                    className="d-block w-100"
                    src={image13}
                    alt="Umar Aliyu Hajji"
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px',
                      height: '250px',  // Fixed height for consistency
                      transition: 'transform 0.3s ease-in-out', // Smooth transition on hover
                    }}
                    onMouseOver={(e) => {
                      e.target.style.transform = 'scale(1.05)'; // Slight zoom on hover
                    }}
                    onMouseOut={(e) => {
                      e.target.style.transform = 'scale(1)'; // Reset zoom
                    }}
                  />

              
<div className="Pard__content">
                          <p className="Pard__title"> Social </p>
                          <p className="Pard__description">
                            Facebook 

                            <i class="bi bi-facebook"  style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                             color: '#1877F2',
                              fontSize: '1.5rem'  // Optional: Increase icon size if needed
                            }}></i>
                          </p>

                          <p className="Pard__description">
                          Linkedin

                            <i class="bi bi-linkedin"
                             style={{
                              marginLeft: '30px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem',  // Optional: Increase icon size if needed
                              color: '#0A66C2' 
                            }}
                            ></i>
                          </p>

                          <p className="Pard__description">
                          Instagram

                            <i class="bi bi-instagram"  
                            style={{
                              marginLeft: '20px', // Adds 10px space between the text and the icon
                              fontSize: '1.5rem' , // Optional: Increase icon size if needed
                               color: '#E4405F',
                            }}></i>
                          </p>
                          
                        </div>
                  </section>
                    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <h5 style={{
                      fontSize: '1.5rem', // Larger font size
                      fontWeight: 'bold', // Bold for emphasis
                      color: 'black', // Attractive blue color
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                      Adaorah Orkafansi
                    </h5>
                    <p style={{
                      fontSize: '1rem',
                      // color: 'red', // Subtle gray color for paragraph
                      fontStyle: 'italic', // Italicize for elegance
                      transition: 'color 0.3s ease', // Smooth color transition
                    }}>
                     Head, Human Resources
                    </p>
                  </div>
                </div>
            </Col>
          </Row>
        </div>
      </Container>
      <div>
      <div className="text-center mb-4">
     
     
     
      

        
      </div>
    </div>

    <div className='FAQ'>

    </div>
    <div>

    </div>


<div >

          <Container>
          
          </Container>


    </div>
    </div>
  );
}

export default Company;
