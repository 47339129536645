import React from 'react'
import { Container } from 'react-bootstrap'

function Privacy() {
  return (
    <Container><div style={{ marginTop: '8rem' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Privacy Policy</h1>
      </div>

       <div style={{ display: 'flex', marginTop: '5rem', justifyContent: 'space-between'}}>
        
        <div style={{width: '20%'}}>
          <p>
            <a href='#' style={{color: 'red'}}>Introduction</a>
          </p>
          <p>
            <a href='#' style={{color: 'red'}}>Collection of Personal Data</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Use of Personal Data</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Lawful Basis for processing Personal Data</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Disclosure of Personal Data</a>
          </p>
          <p>
            <a href='#' style={{color: 'red'}}> Your Choices</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Your Rights</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Security</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Where we store your personal data</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>How long we keep your personal information</a>
          </p>


          <p>
            <a href='#' style={{color: 'red'}}>Breach of personal data</a>
          </p>

          <p>
            <a href='#' style={{color: 'red'}}>Contact Us</a>
          </p>


        </div>

        <div style={{width: '70%'}}>
          <h2>1. Introduction</h2>
          
          <div>
            <p>Hello there! We've created this Privacy Policy to explain how we collect, use, disclose and protect your information; including any non-public, personal information (Personal Data).</p>
            <p>This Policy applies to the Personal Data, Microbiz (“we”, “us”, “our” ) collects when you use our website and mobile application (collectively, "Services") or otherwise interact with us as described below. We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide you with additional notice (such as by adding a statement to the homepages of our website or mobile application or by sending you an email notification).</p>
            <p>We encourage you to review the Privacy Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy.</p>
          </div>

          <div>
          <h2>2.  Collection of Personal Data</h2>
            <p>You may be asked to provide your information including Personal Data anytime you contact us, and we shall use your Personal Data in accordance with this Privacy Policy.</p>
          </div>

          <div>
            <h2>2.1. Categories of Personal Data</h2>
            <p>We collect Personal Data you provide to us when you apply for a Microbiz account or when you communicate with us about your Microbiz account. The types of Personal Data we may collect include your name, address, phone number, email address, date of birth, bank account information, BVN, username, password, and next of kin details.</p>
          </div>

          <div>
            <h2>2.2. Personal Data About Your Transactions With Us</h2>
            <p>As you use your Microbiz account to perform financial transactions, we collect Personal Data in connection with each transaction, including transaction time, Naira amount and merchant details. We may also collect photos, memos or other information that you attach to your transactions.</p>
          </div>


          <div>
            <h2>2.3. Personal Data From Other Sources</h2>
            <p>When you create a Microbiz account, we may collect information, including Personal Data about you from non-affiliated third party service providers in order to verify your identity and for fraud prevention, including your prior addresses and names.</p>
          </div>

          
          <div>
            <h2>2.4. Other Personal Data we collect</h2>
            <p>We may collect Personal Data you provide to us, such as your name, email address and any other information you choose to provide. For example, we may collect such information if you request an invite to join Microbiz or if you enter into any contest or promotion. In a case where you choose to enable access to your contacts, we may upload your contact data to our servers so we can show you your friends who are on Microbiz.</p>
          </div>

          
          <div>
            <h2>2.5. Personal Data We Collect Automatically from our Services</h2>
            <p>When you access or use our Services, we automatically collect Personal Data and other information about you as follows:</p>

            <ol>
        <li>Log Information: We log information about your use of our Services, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to our Services.</li>
     
        <li>Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers and mobile network information.</li>
       
        <li>Location Information: We may collect your location information from your mobile device with your prior consent. Also, we have incorporated Google Maps into our Services, and you may consent to the collection of location information by Google Maps in connection with your use of this maps service. Any information collected via your use of Google Maps will be transmitted directly to Google and is not collected by us. Please refer to Google’s privacy policy for details about their collection, use and sharing of this information. For information about how to disable the collection of location information from your device, please see “Your Choices” below.</li>
        
        <li>Information Collected by Cookies and Other Tracking Technologies: We use various technologies to collect information on our Services and other websites, and this may include sending cookies to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory that help us to improve our Services and your experience, see which areas and features of our Services are popular and count visits. For more information about cookies, and how to disable them, please see “Your Choices” below. We may also collect information using web beacons (also known as "tracking pixels"). Web beacons are electronic images that may be used in our Services or emails and help deliver cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened and acted upon.</li>
       
        <li>How We Respond to Do Not Track Signals: We allow Do Not Track browser configurations. We use cookies and other tracking technologies, and Do Not Track status may alter the available services with certain browsers, when these tracking functionalities are used to provide services. We do not alter the information our applications attempt to collect based on your Do Not Track configuration.</li>
    </ol>
          </div>

          
          <div>  
            <p>The platform does use third party services that may collect information used to identify you. Links to privacy policy of third party service providers used by the app:</p>

            <ul>
                <li><a href='#'>Google Analytics</a></li>
                <li><a href='#'>Google Play Services</a></li>
                <li><a href='#'>Crashlytics</a></li>
                <li><a href='#'>Facebook</a></li>
            </ul>
          </div>

          
          <div>
            <h2>2.6 Personal data we receive from other sources</h2>
            <p>We work closely with third parties (including financial service providers, security agencies, etc). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.</p>
          </div>

          
          <div>
            <h2>3. USE OF PERSONAL DATA</h2>
            <p>We may use your information, including Personal Data as follows:</p>
            
            <ul>
                <li>Provide, maintain and improve our Services</li>
                <li>Provide and deliver the products and services you request, process transactions and send you related information, including confirmations.</li>
                <li>Verify your identity and prevent fraud.</li>
                <li>Send you technical notices, updates, security alerts and support and administrative messages.</li>
                <li>Respond to your comments, questions and requests and provide customer service.</li>
                <li>Communicate with you about products, services, offers, promotions, rewards, and events offered by Microbiz and others, and provide news and information we think will be of interest to you.</li>
                <li>Monitor and analyze trends, usage and activities in connection with our Services.</li>
                <li>Personalize and improve the Services and provide advertisements, content or features that match user profiles or interests.</li>
                <li>Process and deliver contest or promotion entries and rewards.</li>
                <li>Link or combine with information we get from others to help understand your needs and provide you with better service.</li>
                <li>Carry out any other purpose for which the information was collected.</li>
            </ul>
          </div>

          
          <div>
            <p>Microbiz is based in Nigeria and the Personal Data we collect is governed by the Nigeria Data Protection Regulation (NDPR). By accessing or using the Services or otherwise providing your Personal Data to us, you consent to the processing and transfer of your Personal Data in and to Nigeria and other countries.</p>
          </div>

          
          <div>
            <h2>4. LAWFUL BASIS FOR PROCESSING PERSONAL DATA</h2>
            <p>We will only use your Personal Data if we have a proper reason for doing so. We consider the lawful basis for using your Personal Data as set out in the Nigeria Data Protection Regulation (NDPR). This include:</p>
            <ol>
              <li>To comply with our legal and regulatory obligations.</li>
              <li>To perform a contract with you or to take steps at your request before entering into a contract.</li>
              <li>To use your Personal Data as is necessary for our legitimate interests or the legitimate interests of others.</li>
              <li>To protect your interest, the interest of another Data Subject or for the performance of a task carried out in the public interest or in exercise of official public mandate vested in us.</li>
            </ol>
         
          </div>

          <div>
            <h2>5. DISCLOSURE OF PERSONAL DATA</h2>
            <p>We may disclose any information we collect about current and former customers, including Personal Data, to affiliates and non-affiliated third parties as follows:</p>
            <ol>
              <li>With financial service providers, including the financial institutions identified in your cardholder bank agreement that provide banking services in connection with your Microbiz account.</li>
              <li>With another user, when you sign up for Microbiz's services via a referral link. The user that referred you may receive information indicating that you have enrolled with Microbiz. You may avoid this sharing by not using a referral link to enroll.</li>
              <li>With non-financial companies, such as email service providers that perform marketing services on our behalf, and fraud prevention service providers that use the information to provide services to Microbiz and other companies.
              </li>
              <li>With a non-affiliated third-party to access and transmit your personal and financial information from a relevant financial institution. You grant the third-party the right, power, and authority to access and transmit this information. according to terms of their privacy policy.</li>
            </ol>
          </div>

          <div>
            <p>With other non-affiliated companies for our everyday business purposes, such as to process transactions, maintain accounts, respond to court orders and legal investigations or report to credit bureaus. For example, in connection with our everyday business purposes, we may share Personal Data about you as follows:</p>
            <ol>
              <li>In response to a request for Personal Data, if we are required by, or we believe disclosure is in accordance with, any applicable law, regulation or legal process.</li>
              <li>With relevant law enforcement officials or other third parties, such as investigators or auditors, if we believe it is appropriate to investigate fraud.</li>
              <li>If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property and safety of Microbiz or others.
              </li>
              <li>In connection with, or during negotiations of, any merger, sale of Microbiz's assets, financing or acquisition of all or a portion of our business to another company; and</li>
              <li>With your consent or at your direction, including if we notify you that the Personal Data you provide will be shared in a particular manner and you provide such Personal Data.</li>
            </ol>
          </div>

          <div>
            <p>We may also share aggregated or de-identified Personal Data, which cannot reasonably be used to identify you. For example, we may share transaction zip codes with third parties to improve our accuracy in geo-locating transactions and to improve the clarity of transaction descriptions.</p>
          </div>

          <div>
            <p>We will usually not share your Personal Data with other third parties without your consent. Where we need to transfer your Personal Data to another country, we shall ensure that such country to which the data is being transferred shall have adequate data protection law. We will seek your consent where we need to send your data to a country without an adequate data protection law.</p>
          </div>

          <div>
            <h2>6. Your Choices</h2>
            <h2>6.1. Account Information</h2>
            <p>You may edit your Personal Data by logging into your account via our Services. If you wish to delete or deactivate your online account, please contact us, but note that we may retain certain Personal Data as required by law or for legitimate business purposes.</p>
          </div>

          <div>
            <h2>6.2. Promotional Communications
            </h2>
            <p>You may opt out of receiving promotional emails and text messages from Microbiz by following the instructions in those messages. Please note that if you opt out, we may still send you transactional or relationship messages, such as those about your account or our ongoing business relations.</p>
          </div>

          <div>
            <h2>6.3. Location Information
            </h2>
            <p>If you initially consent to the collection of location information by Microbiz or Google Maps within our mobile application, you may be able to subsequently stop this collection through your device operating system settings or through your online account settings. If either of these opt-out options are not available to you, you may also disable location information by following the standard uninstall process to remove our mobile application from your device.</p>
          </div>

          <div>
            <h2>6.4. Cookies</h2>
            <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.</p>
          </div>

          <div>
            <h2>6.5. Social Sharing Features
            </h2>
            <p>Our Services include social sharing features for common social networks to let users share content. These features are served from third-party services that may serve their own cookies. The use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.</p>
          </div>

          <div>
            <h2>6.6. Analytics and Advertising Services Provided by Others
            </h2>
            <p>We may allow others to provide analytics services on our behalf and to serve advertisements on our behalf across the Internet. These entities may use cookies, web beacons and other technologies to collect information about your use of the Services and other websites, including your IP address, web browser, pages viewed, time spent on pages, links clicked and conversion information. This Personal Data may be used by Microbiz and others, to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising targeted to your interests and better understand your online activity.</p>
          </div>

          <div>
            <h2>7. Your Rights
            </h2>
            <p>The Nigeria Data Protection Regulation (NDPR) gives you certain rights in respect of the Personal Data we hold about you. Below is a highlight of some of those rights. It is not a complete, exhaustive statement of your rights in respect of your Personal Data:</p>
          <ol>
            <li>You have a right to consent to this Privacy Policy and to withdraw your consent at any time.</li>
            <li>You have a right to a copy of the Personal Data we hold about you, as well as the information about what we do with it, who we share it with and how long we hold it for. We may make a reasonable charge for additional copies of that data in the case of unfounded and excessive requests.</li>
            <li>You have a right to freely transfer your data received from us to any other person.</li>
            <li>In some circumstances, you have the right to the deletion of the information that we hold about you.</li>
            <li>You have a right to lodge a complaint about the handling of your personal information with the National Information Technology Development Agency (NITDA).</li>
            <li>You have the right to ask us not to process your Personal Data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your Personal Data to any third party for such purposes.</li>
          </ol>
          <p><a href='#'>NITDA’s website </a>has a wealth of useful information in respect of your rights over your personal data.</p>
          <p>If you wish to exercise your rights, you may write to us at <a href='#'> dpo@microbizmfb.com</a></p>
          </div>

          <div>
            <h2>8. Security
            </h2>
            <p>Microbiz takes reasonable measures to help protect all Personal Data about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. Additionally, Microbiz implements policies designed to protect the confidentiality and security of your nonpublic Personal Data, including a privacy protection policy. Microbiz limits access to your Personal Data to employees that have a business reason to know such Personal Data, and implement security practices and procedures designed to protect the confidentiality and security of such Personal Data and prohibit unlawful disclosure of such Personal Data in accordance with its policies.</p>
            <p>Where you have chosen a password that allows you to access certain parts of the website and mobile application, you are responsible for keeping this password confidential. We advise you not to share your password with anyone. We have also taken measures to comply with provision of security facilities for the protection of your Personal Data through the set up of firewalls, limited access to specified authorized individuals, encryption and continuous capacity building for relevant personnel. We therefore have digital and physical security measures to limit and eliminate possibilities of data privacy breach incidents.</p>
            <p>Although we use appropriate security measures once we have received your Personal Data, the transmission of data over the internet (including by email) is never completely secure. We endeavour to protect Personal Data, but we cannot guarantee the security of Personal Data transmitted to us or by us. We will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
          </div>

          <div>
            <h2>9. WHERE WE STORE YOUR PERSONAL DATA
            </h2>
            <p>The Personal Data that we collect from you will be transferred to and stored at a destination outside Nigeria. By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely and in accordance with this Privacy Policy. All Personal Data you provide to us is stored on our secure cloud storage solution.</p>
          </div>

          <div>
            <h2>10. HOW LONG WE KEEP YOUR PERSONAL INFORMATION
            </h2>
            <p>We will hold your Personal Data on Microbiz’s systems in line with our Retention Schedule to fulfil the purpose for which it was collected or to comply with legal, regulatory or internal policy requirements.</p>
          </div>

          <div>
            <h2>11. BREACH OF PERSONAL DATA
            </h2>
            <p>In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data, we shall within 72 (Seventy-Two) hours of having knowledge of such breach report the details of the breach to NITDA.</p>
            <p>Furthermore, where we ascertain that such breach is detrimental to your rights and freedoms in relation to your Personal Data, we shall within 7 (Seven) days of having knowledge of the occurrence of such breach take steps to inform you of the breach incident, the risk to your rights and freedoms resulting from such breach and any course of action to remedy said breach.</p>

          </div>

          <div>
            <h2>Contact Us
            </h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
          </div>


          <div>
            <h2>Microbiz Data Protection Officer
            </h2>
          <p>
            Block B1, Suites 13-16,
            New Mpape Modern Market,
            Maitama Ext,
            Abuja.
          </p>
          <p><a href='#'>dpo@microbizmfb.com </a></p>
          <p><a href='#'>Effective Date: June 01, 2022</a></p>
          </div>


          <div>
            <h2>6.3. Location Information
            </h2>
            <p>[Content for the fourth section]</p>
          </div>

        </div>

        

      </div>
    </div>
    </Container>
  )
}

export default Privacy