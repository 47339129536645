import React from 'react';
import '../App.css'
import appmockup from './pictures/360shots_so.png';
import appmockup2 from './pictures/USSD.png';
import appmockup3 from './pictures/ibanking.png';

function Heroimage() {
  return (
  <div id="slideshows">
    <div class="slide-wrapperr" style={{display: 'flex', justifyContent: 'space-between'}}>
              <div className='slidee'>
              <img
                src={appmockup}
                alt="App Mockup"
                style={{
                  width: '60rem',
                  height: '30rem',
                  objectFit: 'contain',
                  transform: 'scale(1.06)',
                  transformOrigin: 'center',
                }}
              /> 
              </div>

              <div className='slidee'>
              <img
                src={appmockup3}
                alt="App Mockup"
                style={{
                  width: '60rem',
                  height: '30rem',
                  objectFit: 'contain',
                  transform: 'scale(1.3)',
                  transformOrigin: 'center',
                }}
              /> 
              </div>

              <div className='slidee'>
              <img
                src={appmockup2}
                alt="App Mockup"
                style={{
                  width: '60rem',
                  height: '30rem',
                  objectFit: 'contain',
                  transform: 'scale(1)',
                  transformOrigin: 'center',
                }}
              /> 
              </div>
    </div>
  </div>
  )
}

export default Heroimage
