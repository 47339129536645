import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import image3 from './pictures/992shots_so.png';
import { ReactComponent as GooglePlayIcon } from './pictures/playstore 1.svg';
import logo1 from './pictures/remita-logo.jpg';
import logo2 from './pictures/zenith-bank-logo.jpg';
import logo3 from './pictures/fidelity-bank-logo.jpg';
import logo4 from './pictures/nibss-logo.jpg';
import logo5 from './pictures/access-bank-logo.jpg';
import logo6 from './pictures/partners-worldwide-logo.jpg';
import logo7 from './pictures/crc-cb-logo.jpg';
import logo8 from './pictures/first-central-logo (1).jpg';
import logo9 from './pictures/uba-logo.jpg';
import logo10 from './pictures/peak-mfi-logo.jpg';
import '../App.css';

const FAQ = () => {
  // State to track the currently active accordion item
  const [activeKey, setActiveKey] = useState('0');

  // Handle click to set the active accordion item
  const handleToggle = (key) => {
    setActiveKey(key === activeKey ? '' : key); // Toggle or close if the same key is clicked
  };

  return (
    <div style={{ alignContent: 'center'}}>
      <div className='partners'>
        <Container className="features" style={{ justifyContent: 'center', alignItems: 'center', marginBottom: '100px' }}>
          <Row className="mb-4" style={{ justifyContent: 'center' }}>
            <p style={{
              color: 'white',
              backgroundColor: 'red',
              padding: '1rem',
              borderRadius: '4px',
              textAlign: 'center',
              margin: '0 auto',
              display: 'inline-block',
              width: 'auto'
            }}>
              Our Partners
            </p>
          </Row>
          <Row className="text-center mb-5">
            <Col>
              <h5>Trusted Partnerships for Exceptional Service</h5>
            </Col>
          </Row>
          <div id= 'slideshow'>
            <div className="slide-wrapper justify-content-between" style={{display: 'flex'}}>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo1} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo2} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo3} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo4} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo5} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo6} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo7} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo8} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo9} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
              <Col id='slide' xs={1} md={1} lg={1} className="d-flex justify-content-center mb-4">
                <div><img src={logo10} alt="Partner 1" className="img-fluid" style={{ maxWidth: '8rem', height: 'auto', margin: '1rem' }} /></div>
              </Col>
            </div>
          </div>
        </Container>
      </div>
      <div className="container mt-5" style={{backgroundColor: '#095390', width:'70%',marginBottom: '3rem', padding: '3rem 2rem', borderRadius: '10px', border: 'solid', borderColor: '#093590',}}>
                <Row className="mb-4">
                <p  style={{
                    color: '#095390',
                    backgroundColor: '#ffffff',
                    padding: '1rem',
                    borderRadius: '4px',
                    textAlign: 'center',
                    margin: '0 auto',
                    display: 'inline-block',  // This ensures the width wraps the content
                    width: 'auto'             // Makes sure the width adjusts based on the content
                  }}>FAQ</p>
              </Row>
              <Row className="text-center mb-4">
                <Col>
                  <h5 style={{color: '#ffffff'}}>Explore Our Comprehensive Suite of Services</h5>
                </Col>
              </Row>
              <div className="accordion" id="faqAccordion" style={{ display: 'grid', gap: '1rem' }}>
        {/* Accordion Item 1 */}
        <div className="card gradient-border" style={{ backgroundColor: '#064481'}}>
          <div className="card-header" id="headingOne" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none' }}>
            <div style={{ color: '#ffffff', alignSelf: 'center' }}>
              What is Microbiz?
            </div>
            <button
              className="btn btn-link white-button"
              type="button"
              aria-expanded={true}
              onClick={() => document.getElementById('collapseOne').classList.toggle('show')}
            >
              <i className="bi bi-chevron-down float-right"></i>
            </button>
          </div>
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingOne"
          >
            <div className="card-body" style={{ border: 'none', borderTop: '1px solid', borderColor: '#095390', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'  }}>
              <span style={{ color: '#ffffff', fontSize: '85%' }}>
                An innovative Microfinance Bank driven by passion and commitment to provide life-changing solutions towards sustainable empowerment for everyday people.
              </span>
            </div>
          </div>
        </div>

        {/* Accordion Item 2 */}
        <div className="card gradient-border" style={{ backgroundColor: '#064481'}}>
          <div className="card-header" id="headingTwo" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none' }}>
            <div style={{ color: '#ffffff', alignSelf: 'center' }}>
              How can I open an account?
            </div>
            <button
              className="btn btn-link white-button"
              type="button"
              aria-expanded={true}
              onClick={() => document.getElementById('collapseTwo').classList.toggle('show')}
            >
              <i className="bi bi-chevron-down float-right"></i>
            </button>
          </div>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
          >
            <div className="card-body" style={{ border: 'none', borderTop: '1px solid', borderColor: '#095390', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'  }}>
              <span style={{ color: '#ffffff', fontSize: '85%' }}>
                You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>

        {/* Accordion Item 3 */}
        <div className="card gradient-border" style={{ backgroundColor: '#064481'}}>
          <div className="card-header" id="headingThree" style={{ display: 'flex', justifyContent: 'space-between', border: 'none' }}>
            <div style={{ color: '#ffffff', alignSelf: 'start' }}>
             How much to open an account?
            </div>
            <button
              className="btn btn-link white-button"
              type="button"
              aria-expanded={true}
              onClick={() => document.getElementById('collapseThree').classList.toggle('show')}
            >
              <i className="bi bi-chevron-down float-right"></i>
            </button>
          </div>
          <div
            id="collapseThree"
            className="collapse"
            aria-labelledby="headingThree"
          >
            <div className="card-body" style={{ border: 'none', borderTop: '1px solid', borderColor: '#095390', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'  }}>
              <span style={{ color: '#ffffff', fontSize: '85%' }}>
                You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>

        {/* Accordion Item 4 */}
        <div className="card gradient-border" style={{ backgroundColor: '#064481'}}>
          <div className="card-header" id="headingFour" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none' }}>
            <div style={{ color: '#ffffff', alignSelf: 'center' }}>
              How can I open an account?
            </div>
            <button
              className="btn btn-link white-button"
              type="button"
              aria-expanded={true}
              onClick={() => document.getElementById('collapseFour').classList.toggle('show')}
            >
              <i className="bi bi-chevron-down float-right"></i>
            </button>
          </div>
          <div
            id="collapseFour"
            className="collapse"
            aria-labelledby="headingFour"
          >
            <div className="card-body" style={{ border: 'none', borderTop: '1px solid', borderColor: '#095390', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'  }}>
              <span style={{ color: '#ffffff', fontSize: '85%' }}>
                You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>

        {/* Accordion Item 5 */}
        <div className="card gradient-border" style={{ backgroundColor: '#064481'}}>
          <div className="card-header" id="headingFive" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: 'none' }}>
            <div style={{ color: '#ffffff', alignSelf: 'center' }}>
              Is my money safe with Microbiz?
            </div>
            <button
              className="btn btn-link white-button"
              type="button"
              aria-expanded={true}
              onClick={() => document.getElementById('collapseFive').classList.toggle('show')}
            >
              <i className="bi bi-chevron-down float-right"></i>
            </button>
          </div>
          <div
            id="collapseFive"
            className="collapse"
            aria-labelledby="headingFive"
          >
            <div className="card-body" style={{ border: 'none', borderTop: '1px solid', borderColor: '#095390', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
              <span style={{ color: '#ffffff', fontSize: '85%' }}>
                You can track your order using the tracking number provided in your shipment confirmation email.
              </span>
            </div>
          </div>
        </div>
      </div>

      </div>
      <Col className='another' xs={1} md={10} lg={10} style={{ alignContent: 'center', alignSelf: 'center', width: '100%'}}>
        <div style={{ alignContent: 'center', alignItems: 'center', display: 'flex', justifyContent: 'space-around'}}>
        <div className='background-image' style={{ width: '60rem', marginTop: '100px', marginBottom: '100px', alignItems:'center', alignContent:'center', border: 'solid', borderRadius: '20px', borderWidth: '2px', borderColor: '#fdfdfd', boxShadow: '1px 3px 25px 10px rgb(240, 240, 240, 0.8)'}}>
            <Container fluid style={{ gap: '20px' }}>
              <Row className='align-items-center justify-content-between flex-column flex-md-row' style={{ padding: '1rem', width: '100%' }}>
                <Col s={2} md={5} className='d-flex align-items-center justify-content-center mb-4 mb-md-0'>
                  <img
                    src={image3}
                    alt="App Mockup"
                    style={{
                      width: '100%',
                      maxWidth: '40rem',
                      height: 'auto',
                      objectFit: 'cover',
                      objectPosition: 'top',
                      borderRadius: '10px'
                    }}
                  />
                </Col>
                <Col md={6} className='d-flex flex-column align-items-start text-white' style={{ padding: '1rem' }}>
                  <h1 style={{ textAlign: 'left', color: 'black', lineHeight: 1.3 }}>
                    Discover Banking That Excels
                  </h1>
                  <div className='d-flex flex-md-row justify-content-center' style={{ gap: '1rem'}}>
                    <Button variant='dark' style={{ color: '#ffffff', borderColor: '#000000' }}>
                      <i className="fab fa-apple fa-xl" style={{ paddingRight: '0.5rem' }}></i> <p className='d-inline' style={{fontSize: '80%'}}>App Store</p>
                    </Button>
                    <Button variant='dark' style={{ color: '#ffffff', borderColor: '#000000'}}>
                      <GooglePlayIcon width="24" height="24" /> <p className='d-inline' style={{fontSize: '80%'}}>Play Store</p>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
        </div>
      </Col>
    </div>
  );
};

export default FAQ;
